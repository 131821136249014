/**
==============================================================================
Copyright (C) 2019 - Philip Paquette, Steven Bocco

 This program is free software: you can redistribute it and/or modify it under
 the terms of the GNU Affero General Public License as published by the Free
 Software Foundation, either version 3 of the License, or (at your option) any
 later version.

 This program is distributed in the hope that it will be useful, but WITHOUT
 ANY WARRANTY; without even the implied warranty of MERCHANTABILITY or FITNESS
 FOR A PARTICULAR PURPOSE.  See the GNU Affero General Public License for more
 details.

 You should have received a copy of the GNU Affero General Public License along
 with this program.  If not, see <https:www.gnu.org/licenses/>.
==============================================================================
**/
/* text */
.SvgStandard svg {
  font-size: 100%;
}
.SvgStandard .labeltext24 {
  text-anchor: middle;
  stroke-width: 0.1;
  stroke: black;
  fill: black;
  font-family: serif, sans-serif;
  font-style: italic;
  font-size: 1.4em;
}
.SvgStandard .labeltext18 {
  text-anchor: middle;
  stroke-width: 0.1;
  stroke: black;
  fill: black;
  font-family: serif, sans-serif;
  font-style: italic;
  font-size: 1.1em;
}
.SvgStandard .currentnotetext {
  font-family: serif, sans-serif;
  font-size: 1.5em;
  fill: black;
  stroke: black;
}
/* .SvgStandard .currentnoterect {fill:#c5dfea;}  */
.SvgStandard .currentnoterect {
  fill: color-mix(in oklab, #c5dfea, white 25%);
}
.SvgStandard .currentphasetext {
  font-family: serif, sans-serif;
  font-size: 2.5em;
  fill: black;
  stroke: black;
}
.SvgStandard .labeltext24 text {
  cursor: default;
} /* map and object features */
.SvgStandard .impassable {
  fill: #353433;
  stroke: #000000;
  stroke-width: 1;
} /* invisible click rects fill:none does not work */
.SvgStandard .invisibleContent {
  stroke: #000000;
  fill: #000000;
  fill-opacity: 0;
  opacity: 0;
} /* default region coloring, by power */
.SvgStandard .provinceRed {
  fill: url(#patternRed);
}
.SvgStandard .provinceBrown {
  fill: url(#patternBrown);
}
.SvgStandard .provinceGreen {
  fill: url(#patternGreen);
}
.SvgStandard .provinceBlack {
  fill: url(#patternBlack);
}
.SvgStandard .provinceBlue {
  fill: url(#patternBlue);
}

.noselect {
  /* https://stackoverflow.com/questions/826782/how-to-disable-text-selection-highlighting */
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                    supported by Chrome, Edge, Opera and Firefox */
}

.SvgStandard .nopower {
  fill: antiquewhite;
  stroke: #000000;
  stroke-width: 1;
}
.SvgStandard .nopowerlight {
  fill: color-mix(in oklab, antiquewhite, white 25%);
  stroke: #000000;
  stroke-width: 1;
}

.SvgStandard .water {
  fill: #c5dfea;
  stroke: #000000;
  stroke-width: 1;
}
.SvgStandard .waterlight {
  fill: color-mix(in oklab, #c5dfea, white 25%);
  stroke: #000000;
  stroke-width: 1;
}

.SvgStandard .neutral {
  fill: lightgray;
  stroke: #000000;
  stroke-width: 1;
}
.SvgStandard .neutrallight {
  fill: color-mix(in oklab, lightgray, white 25%);
  stroke: #000000;
  stroke-width: 1;
}

.SvgStandard .austria {
  fill: #c48f85;
  stroke: #000000;
  stroke-width: 1;
}
.SvgStandard .austrialight {
  fill: color-mix(in oklab, #c48f85, white 25%);
  stroke: #000000;
  stroke-width: 1;
}

.SvgStandard .england {
  fill: darkviolet;
  stroke: #000000;
  stroke-width: 1;
}
.SvgStandard .englandlight {
  fill: color-mix(in oklab, darkviolet, white 25%);
  stroke: #000000;
  stroke-width: 1;
}

.SvgStandard .france {
  fill: royalblue;
  stroke: #000000;
  stroke-width: 1;
}
.SvgStandard .francelight {
  fill: color-mix(in oklab, royalblue, white 25%);
  stroke: #000000;
  stroke-width: 1;
}

.SvgStandard .germany {
  fill: #a08a75;
  stroke: #000000;
  stroke-width: 1;
}
.SvgStandard .germanylight {
  fill: color-mix(in oklab, #a08a75, white 25%);
  stroke: #000000;
  stroke-width: 1;
}

.SvgStandard .italy {
  fill: forestgreen;
  stroke: #000000;
  stroke-width: 1;
}
.SvgStandard .italylight {
  fill: color-mix(in oklab, forestgreen, white 25%);
  stroke: #000000;
  stroke-width: 1;
}

.SvgStandard .russia {
  fill: #757d91;
  stroke: #000000;
  stroke-width: 1;
}
.SvgStandard .russialight {
  fill: color-mix(in oklab, #757d91, white 25%);
  stroke: #000000;
  stroke-width: 1;
}

.SvgStandard .turkey {
  fill: #b9a61c;
  stroke: #000000;
  stroke-width: 1;
} /* unit colors, by power note that underscores are not supported */
.SvgStandard .turkeylight {
  fill: color-mix(in oklab, #b9a61c, white 25%);
  stroke: #000000;
  stroke-width: 1;
} /* unit colors, by power note that underscores are not supported */

.SvgStandard .unitaustria {
  fill: red;
  fill-opacity: 0.85;
}
.SvgStandard .unitengland {
  fill: mediumpurple;
  fill-opacity: 0.85;
}
.SvgStandard .unitfrance {
  fill: deepskyblue;
  fill-opacity: 0.85;
}
.SvgStandard .unitgermany {
  fill: dimgray;
  fill-opacity: 0.85;
}
.SvgStandard .unititaly {
  fill: olive;
  fill-opacity: 0.85;
}
.SvgStandard .unitrussia {
  fill: white;
  fill-opacity: 1;
}
.SvgStandard .unitturkey {
  fill: yellow;
  fill-opacity: 0.85;
} /* order drawing styles, stroke and fill colors should not be specified */
.SvgStandard .supportorder {
  stroke-width: 6;
  fill: none;
  stroke-dasharray: 5, 5;
}
.SvgStandard .convoyorder {
  stroke-dasharray: 15, 5;
  stroke-width: 6;
  fill: none;
}
.SvgStandard .shadowdash {
  stroke-width: 10;
  fill: none;
  stroke: black;
  opacity: 0.45;
}
.SvgStandard .varwidthorder {
  fill: none;
}
.SvgStandard .varwidthshadow {
  fill: none;
  stroke: black;
}
