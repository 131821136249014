/* .clickedSlider .unclickedSlider {
    appearance: none;
    -webkit-appearance: none;
}

input[type=range]::-webkit-slider-thumb{
    height: 16px;
    width: 16px;
    border-radius: 50%;
}

input[type=range]::-moz-range-thumb {
    height: 16px;
    width: 16px;
    border-radius: 50%;
}

input[type=range]::-ms-thumb {
    height: 16px;
    width: 16px;
    border-radius: 50%;
}

.unclickedSlider::-webkit-slider-thumb {
    cursor: pointer;
    background: red;
}

.unclickedSlider::-moz-range-thumb {
    cursor: pointer;
    background: red;
}

.unclickedSlider::-ms-thumb {
    cursor: pointer;
    background: red;
}

.clickedSlider::-webkit-slider-thumb {
    cursor: pointer;
    background: #3399FF;
}

.clickedSlider::-moz-range-thumb {
    cursor: pointer;
    background: #3399FF;
}

.clickedSlider::-ms-thumb {
    cursor: pointer;
    background: #3399FF;
} */

.unclickedSlider {
    color: red;
    font-weight: bold;
}